<template>
  <ModalWrapper
    modalName="deleteConfirmModal"
    maxWidth="max-w-2xl"
    :closeButton="false"
  >
    <div class="">
      <div class="w-full">
        <p class="text-darkestGray text-2xl font-medium mb-10">
          {{ title }}
        </p>
      </div>

      <span v-html="text"></span>

      <div>
        <div
          class="flex justify-center md:justify-end items-center space-x-8 text-sm mt-4"
        >
          <button
            @click="onClickCancel"
            size="lg"
            theme="link"
            class="text-primary hover:underline hover:brightness-50"
          >
            {{ $t("action.cancel") }}
          </button>

          <Button
            theme="primary"
            size="lg"
            class="w-40 py-2 px-4 relative bg-danger-100 text-yellow-50"
            @click="onClickConfirm"
          >
            {{ $t("action.confirm") }}
          </Button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import isMobile from "@/mixins/isMobile";
import ModalWrapper from "@/components/Core/ModalWrapper.vue";
import Button from "@/components/Core/ButtonBase.vue";

export default {
  name: "DeleteConfirmModal",
  components: {
    ModalWrapper,
    Button,
  },
  mixins: [isMobile],
  computed: {
    confirm() {
      return this.getModalData("deleteConfirmModal").onConfirm;
    },
    cancel() {
      return this.getModalData("deleteConfirmModal").onCancel;
    },
    title() {
      return this.getModalData("deleteConfirmModal").title;
    },
    text() {
      return this.getModalData("deleteConfirmModal").text;
    },
    ...mapGetters({ getModalData: "modals/getModalData" }),
  },
  methods: {
    onClickCancel() {
      this.cancel();
      this.closeModal("deleteConfirmModal");
    },
    onClickConfirm() {
      this.confirm();
      this.closeModal("deleteConfirmModal");
    },
    ...mapMutations({
      closeModal: "modals/closeModal",
    }),
  },
};
</script>
