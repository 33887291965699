import {
  getFromSessionStorage,
  saveToSessionStorage,
} from "../../utils/localstorage";
import http from "@/utils/axios.js";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    id: getFromSessionStorage("reservation_id", null),
    date: getFromSessionStorage("reservation_date", null),
  },
  getters: {
    getReservationId: (state) => state.id,
    getReservationDate: (state) => state.date,
  },
  mutations: {
    setReservationId(store, id) {
      store.id = id;
      saveToSessionStorage("reservation_id", store.id);
    },
    setReservationDate(store, date) {
      store.date = date;
      saveToSessionStorage("reservation_date", store.date);
    },
  },
  actions: {
    async makeReservation({ getters, rootGetters, commit }) {
      if (!rootGetters["checkout/getAppointmentDate"]) return;

      const reservationDate = moment(
        `${rootGetters["checkout/getAppointmentDate"].date} ${rootGetters["checkout/getAppointmentDate"].start}`,
        "DD.MM.YYYY hh:mm"
      );

      const bodyData = {
        previous_id: getters.getReservationId,
        zone_id: rootGetters["checkout/getCityZone"],
        date: reservationDate.format("YYYY-MM-DD hh:mm"),
        city_uuid: rootGetters["cart/getSelectedCity"].uuid,
        postal_code: rootGetters["checkout/getAddressData"].zip_code,
      };

      const { data } = await http.post("/appointments/reservation", bodyData);
      commit("setReservationId", data.reservation.id);
      commit("setReservationDate", data.reservation.date);

      if (!data.reservation.id) {
        commit("setReservationId", null);
        commit("setReservationDate", null);
        throw "reservation_date_not_available";
      }
    },
    async makeRetryReservation(state, payload) {
      const { data } = await http.post(
        "/appointments/reservation/retry",
        payload
      );

      if (!data.reservation.id) {
        throw "reservation_date_not_available";
      }

      return data.reservation;
    },
  },
};
