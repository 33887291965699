import store from "@/store";
import { mapActions } from "vuex";
import { getFromSessionStorage } from "@/utils/localstorage";

export default {
  computed: {
    isIframe() {
      return window.location !== window.parent.location;
    },
  },
  methods: {
    handleAddPatients(patients) {
      store.commit("checkout/setTmpPatients", patients);
      store.commit("checkout/saveTmpPatients");

      const storePatients = store.getters["checkout/getPatients"];

      if (storePatients.length === 1) {
        store.dispatch("cart/assignCartToPatients", [storePatients[0].id]);
      }
    },
    returnAuthToken(source) {
      const token = getFromSessionStorage("authtoken", null);

      source.postMessage(
        {
          action: "returnAuthToken",
          value: token,
        },
        "*"
      );
    },
    returnInitData(source) {
      const cart = store.getters["cart/getCart"];
      const selectedCity = store.getters["cart/getSelectedCity"];
      const selectedFacility = store.getters["cart/getSelectedFacility"];
      const isHomeService = store.getters["cart/isHomeService"];
      const discountCode = store.getters["cart/getDiscountCode"];
      const patients = store.getters["checkout/getPatients"];
      const nursingServices = store.getters["examinations/getNursingServices"];

      source.postMessage(
        {
          action: "returnInitData",
          value: JSON.stringify({
            cart,
            selectedCity,
            selectedFacility,
            isHomeService,
            discountCode,
            patients,
            nursingServices,
          }),
        },
        "*"
      );
    },
    ...mapActions({
      fetchNursingServices: "examinations/fetchNursingServices",
    }),
  },
  async mounted() {
    if (this.isIframe) {
      await this.fetchNursingServices();

      const domains = process.env.VUE_APP_SHARED_DATA_HOSTS ?? [];

      window.addEventListener(
        "message",
        (event) => {
          if (!domains.includes(event.origin)) return;

          const { action, value } = event.data;

          switch (action) {
            case "getAuthToken":
              this.returnAuthToken(event.source);
              break;
            case "getInitData":
              this.returnInitData(event.source);
              break;
            case "setCartItem":
              store.dispatch("cart/manageCart", value);
              break;
            case "updateCartItemsPrices":
              store.dispatch("cart/updateCartItemsPrices", value);
              break;
            case "removeCartItem":
              store.dispatch("cart/removeItemFromCart", value);
              break;
            case "setLang":
              store.dispatch("language/setCurrentLanguageByCode", value);
              this.$root.$i18n.locale = value;
              break;
            case "logoutUser":
              store.dispatch("auth/logout");
              break;
            case "logInUser":
              store.commit("auth/setLoginToken", value.token);
              store.commit("user/setCurrentUser", value.user);
              break;
            case "changedSelectedCity":
              store.dispatch("cart/updateSelectedCity", value);
              break;
            case "changedFacility":
              store.commit("cart/setSelectedFacility", value);
              break;
            case "changedIsHomeService":
              store.dispatch("cart/updateIsHomeservice", value);
              break;
            case "addPatients":
              this.handleAddPatients(value);
              break;
            case "removePatient":
              store.commit("checkout/deletePatient", value);
              store.dispatch("cart/removePatientFromExaminations", value);
              break;
            case "updatePatient":
              store.commit("checkout/updatePatient", {
                id: value.id,
                patient: value.data,
              });
              break;
            case "clearCart":
              store.commit("cart/clearAll");
              break;
            case "clearPatients":
              store.commit("checkout/clearPatients");
              break;
            case "createOrUpdatePatient":
              store.dispatch("checkout/createOrUpdatePatient", value);
              break;
            case "realizeDiscountCode":
              store.commit("cart/setDiscountCode", value);
              break;
            case "logInByOldSystem":
              store.commit("auth/setOldAccountCredentials", value);
              break;

            default:
              break;
          }
        },
        false
      );
    }
  },
};
