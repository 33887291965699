import { createStore } from "vuex";
// import { createLogger, createStore } from "vuex";

import auth from "./modules/auth";
import billingData from "./modules/billingData";
import cart from "./modules/cart";
import doctors from "./modules/doctors";
import examinations from "./modules/examinations";
import facilities from "./modules/facilities";
import language from "./modules/language";
import languages from "./modules/languages";
import medicalCategories from "./modules/medicalCategories";
import message from "./modules/message";
import modals from "./modules/modals";
import patients from "./modules/patients";
import referrals from "./modules/referrals";
import statuses from "./modules/statuses";
import user from "./modules/user";
import loader from "./modules/loader";
import results from "./modules/results";
import invoices from "./modules/invoices";
import checkout from "./modules/checkout";
import reservation from "./modules/reservation";
import breadcrumbs from "./modules/breadcrumbs";
import referralGroup from "./modules/referralGroup";
import features from "./modules/features";
import doctorReferral from "./modules/doctorReferral";
import app from "./modules/app";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    language,
    languages,
    user,
    medicalCategories,
    auth,
    patients,
    facilities,
    modals,
    examinations,
    referrals,
    cart,
    doctors,
    statuses,
    message,
    billingData,
    loader,
    results,
    invoices,
    checkout,
    reservation,
    breadcrumbs,
    referralGroup,
    features,
    doctorReferral,
    app,
  },
  // plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
  strict: process.env.NODE_ENV !== "production",
});
