import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    referral_group_id: null,
    address: {
      zip_code: null,
      street: null,
      home_number: null,
      premises_number: null,
      level: null,
      gate_code: null,
      additonal_comments: null,
    },
    want_invoice: false,
    invoice_data: {
      first_name: null,
      last_name: null,
      street: null,
      company_name: null,
      nip: null,
      home_number: null,
      premises_number: "",
      zip_code: null,
      city: null,
      additional_comments: null,
      individual: true,
    },
    doctor: null,
    patient: null,
    zone_id: null,
    city: null,
    facility: null,
    is_home_service: null,
    visit_date: null,
    is_confirm_address: false,
  },
  getters: {
    getReferralGroupId: (state) => state.referral_group_id,
    getAddress: (state) => state.address,
    getAdditionalData: (state) => ({
      level: state.level,
      gate_code: state.gate_code,
      additional_comments: state.additonal_comments,
    }),
    getZoneId: (state) => state.zone_id,
    getInvoiceData: (state) => state.invoice_data,
    wantInvoice: (state) => state.want_invoice,
    getDoctor: (state) => state.doctor,
    getPatient: (state) => state.patient,
    getZipCode: (state) => state.address.zip_code,
    getCity: (state) => state.city,
    getFacility: (state) => state.facility,
    isHomeService: (state) => state.is_home_service,
    getVisitDate: (state) => state.visit_date,
    isConfirmAddress: (state) => state.is_confirm_address,
  },
  mutations: {
    setReferralGroupId(state, id) {
      state.referral_group_id = id;
    },
    setAddress(state, address) {
      state.address = { ...state.address, ...address };
    },
    updateAddress(state, { key, value }) {
      state.address = { ...state.address, [key]: value };
    },
    setZoneId(state, zoneId) {
      state.zone_id = zoneId;
    },
    setInvoiceData(state, invoiceData) {
      state.invoice_data = { ...state.invoice_data, ...invoiceData };
    },
    updateInvoiceData(state, { key, value }) {
      state.invoice_data = { ...state.invoice_data, [key]: value };
    },
    setWantInvoice(state, wantInvoice) {
      state.want_invoice = wantInvoice;
    },
    setDoctor(state, doctor) {
      state.doctor = doctor;
    },
    setPatient(state, patient) {
      state.patient = patient;
    },
    setCity(state, city) {
      state.city = city;
    },
    setFacility(state, facility) {
      state.facility = facility;
    },
    setIsHomeService(state, isHomeService) {
      state.is_home_service = isHomeService;
    },
    setVisitDate(state, visitDate) {
      state.visit_date = visitDate;
    },
    setIsConfirmAddress(state, isConfirm) {
      state.is_confirm_address = isConfirm;
    },
  },
  actions: {
    async storeAddress({ getters }) {
      let payload = {
        invoice: {
          ...getters.getInvoiceData,
          wantInvoice: getters.wantInvoice,
        },
      };

      if (getters.isHomeService) {
        payload = {
          ...payload,
          address: getters.getAddress,
          additionalData: getters.getAdditionalData,
        };
      }

      await http.post("/referralGroups/save-address", {
        referralGroupId: getters.getReferralGroupId,
        ...payload,
      });
    },
    setDefaultInvoiceData({ getters, commit }) {
      const address = getters.getAddress;
      const patient = getters.getPatient;

      commit("setInvoiceData", {
        first_name: patient.first_name,
        last_name: patient.last_name,
        street: address.street,
        home_number: address.home_number,
        premises_number: address.premises_number,
        zip_code: address.zip_code,
        city: address.city,
        additonal_comments: address.additonal_comments,
      });
    },
  },
};
