<template>
  <ModalWrapper :modalName="modalName" maxWidth="max-w-2xl" @closed="cancel">
    <div>
      <div class="w-full">
        <p class="text-darkestGray text-2xl font-medium mb-4" v-html="title"></p>
      </div>

      <span v-html="text"></span>

      <div>
        <div class="flex justify-center md:justify-end items-center space-x-8 text-sm mt-10">
          <Button theme="link" @click="onClickCancel" class="text-primary hover:underline hover:brightness-50">
            {{ $t("action.cancel") }}
          </Button>

          <Button theme="primary" class="w-40" @click="onClickConfirm">
            {{ $t("action.confirm") }}
          </Button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import isMobile from "@/mixins/isMobile";
import ModalWrapper from "@/components/Core/ModalWrapper.vue";
import Button from "@/components/Core/ButtonBase.vue";

export default {
  name: "ModalConfirm",
  components: {
    ModalWrapper,
    Button,
  },
  mixins: [isMobile],
  data() {
    return {
      modalName: "confirmModal",
    };
  },
  computed: {
    confirm() {
      return this.getModalData(this.modalName).onConfirm;
    },
    cancel() {
      return this.getModalData(this.modalName).onCancel;
    },
    title() {
      return this.getModalData(this.modalName).title;
    },
    text() {
      return this.getModalData(this.modalName).text;
    },
    ...mapGetters({ getModalData: "modals/getModalData" }),
  },
  methods: {
    onClickCancel() {
      this.cancel();
      this.closeModal(this.modalName);
    },
    onClickConfirm() {
      this.confirm();
      this.closeModal(this.modalName);
    },
    ...mapMutations({
      closeModal: "modals/closeModal",
    }),
  },
};
</script>
