import {
  getFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from "@/utils/localstorage";
import http from "@/utils/axios.js";
import { pick, forEach, set } from "lodash";

const initPersonalData = {
  first_name: "",
  last_name: "",
  country: "PL",
  phone_number_country: "",
  phone_number_country_calling_code: "",
  national_phone_number: "",
  email: "",
  isWithoutRegister: true,
};

const initAddresData = {
  id: null,
  city: "",
  home_number: "",
  premises_number: "",
  street: "",
  zip_code: "",
  additional_comments: "",
  gate_code: "",
  level: "",
  zone_id: "",
};

const initInvoiceData = {
  id: null,
  individual: true,
  company_name: "",
  first_name: "",
  last_name: "",
  nip: "",
  street: "",
  home_number: "",
  premises_number: "",
  zip_code: "",
  city: "",
  additonal_comments: "",
};

export default {
  namespaced: true,
  state: {
    patients: getFromSessionStorage("patients_data", []),
    tmpPatients: [],
    selectedPatient: null,
    address: getFromSessionStorage("address", initAddresData),
    personal_data: getFromSessionStorage("personal_data", initPersonalData),
    appointmentDate: getFromSessionStorage("appointment_date", null),
    paymentMethod: getFromSessionStorage("payment_method", "blik"),
    wantInvoice: getFromSessionStorage("want_invoice", false),
    invoiceData: getFromSessionStorage("invoice_data", initInvoiceData),
    confirmedAddress: false,
    errors: {},
    requestError: {},
    isValidTerms: false,
  },
  getters: {
    getPatients: (state) => state.patients,
    getAdultPatients: (state) =>
      state.patients.filter((patient) => patient.age === "adult"),
    hasPatients: (state) => state.patients.length > 0,
    getPatientByPhone: (state) => (phoneNumber) => {
      return state.patients.find(
        (patient) => patient.phone_number == phoneNumber
      );
    },
    getPatientById: (state) => (id) =>
      state.patients.find((patient) => patient.id == id),
    getPatientsIds: (state) => state.patients.map((patient) => patient.id),
    getTmpPatients: (state) => state.tmpPatients,
    getTmpPatientsIds: (state) =>
      state.tmpPatients.map((patient) => patient.id),
    getSelectedPatient: (state) => state.selectedPatient,
    getAddressData: (state) => state.address,
    getPersonalData: (state) => state.personal_data,
    getAppointmentDate: (state) => state.appointmentDate,
    getPaymentMethod: (state) => state.paymentMethod,
    getCityZone: (state) => state?.address?.zone_id,
    getWantInvoice: (state) => state.wantInvoice,
    getInvoiceData: (state) => state.invoiceData,
    getErrors: (state) => state.errors,
    getRequestError: (state) => state.requestError,
    getPatientErrors: (state) => (index) =>
      state.requestError?.patients ? state.requestError?.patients[index] : [],
    getAddressErrors: (state) => state.requestError?.address ?? [],
    isConfirmedAddress: (state) => state.confirmedAddress,
    isValidTerms: (state) => state.isValidTerms,
  },
  mutations: {
    setErrors(store, errors) {
      store.errors = { ...errors };
    },
    clearErrors(store) {
      store.errors = {};
    },
    setRequestError(store, requestError) {
      store.requestError = requestError;
    },
    clearRequestError(store) {
      store.requestError = {};
    },
    setSelectedPatient(store, patient) {
      store.selectedPatient = { ...patient };
    },
    clearSelectedPatient(store) {
      store.selectedPatient = null;
    },
    setUpTmpPatients(store) {
      store.tmpPatients = [...store.patients];
    },
    setTmpPatients(store, tmpPatients) {
      store.tmpPatients = [...tmpPatients];
    },
    pushTmpPatient(store, payload) {
      store.tmpPatients.push({ ...payload });
    },
    updateTmpPatient(store, { id, patient }) {
      const index = store.tmpPatients.findIndex((patient) => patient.id === id);
      store.tmpPatients[index] = { ...patient };
    },
    savePatient(store, patient) {
      store.patients = [...store.patients, patient];
      saveToSessionStorage("patients_data", store.patients);
    },
    updatePatient(store, { id, patient }) {
      const index = store.patients.findIndex((patient) => patient.id === id);
      store.patients[index] = { ...store.patients[index], ...patient };
      saveToSessionStorage("patients_data", store.patients);
    },
    deletePatient(store, patientId) {
      const index = store.patients.findIndex(
        (patient) => patient.id === patientId
      );
      store.patients.splice(index, 1);
      saveToSessionStorage("patients_data", store.patients);
    },
    deleteTmpPatient(store, patientId) {
      const index = store.tmpPatients.findIndex(
        (patient) => patient.id === patientId
      );
      store.tmpPatients.splice(index, 1);
    },
    clearTmpPatients(store, age = null) {
      if (age) {
        store.tmpPatients = store.tmpPatients.filter(
          (patient) => patient.age != age
        );
      } else {
        store.tmpPatients = [];
      }
    },
    saveTmpPatients(store) {
      store.patients = [...store.patients, ...store.tmpPatients];
      store.tmpPatients = [];
      saveToSessionStorage("patients_data", store.patients);
    },
    setAddressData(store, payload) {
      store.address = { ...store.address, ...payload };
      saveToSessionStorage("address", store.address);
    },
    setAddressDataSilent(store, payload) {
      store.address = { ...store.address, ...payload };
    },
    setPersonalData(store, payload) {
      store.personal_data = { ...store.personal_data, ...payload };
      saveToSessionStorage("personal_data", store.personal_data);
    },
    setPersonalDataSilent(store, payload) {
      store.personal_data = { ...store.personal_data, ...payload };
    },
    setAppoitmentDate(store, date) {
      store.appointmentDate = date;
      saveToSessionStorage("appointment_date", store.appointmentDate);
    },
    setPaymentMethod(store, method) {
      store.paymentMethod = method;
      saveToSessionStorage("payment_method", store.paymentMethod);
    },
    setWantInvoice(store, state) {
      store.wantInvoice = state;
      saveToSessionStorage("want_invoice", store.wantInvoice);
    },
    setInvoiceData(store, data) {
      store.invoiceData = data;
      saveToSessionStorage("invoice_data", store.wantInvoice);
    },
    setInvoiceDataSilent(store, data) {
      store.invoiceData = { ...store.invoiceData, ...data };
    },
    setInvoiceDataValue(store, { key, value }) {
      store.invoiceData[key] = value;
      saveToSessionStorage("invoice_data", store.invoiceData);
    },
    setZoneId(store, zone_id) {
      store.address = { ...store.address, zone_id };
      saveToSessionStorage("address", store.address);
    },
    setConfirmedAddress(store, state) {
      store.confirmedAddress = state;
    },
    setIsValidTerms(store, state) {
      store.isValidTerms = state;
    },
    clearData(store) {
      store.appointmentDate = null;
      store.paymentMethod = "fast_transfer";
      store.wantInvoice = null;
      store.invoiceData = null;

      removeFromSessionStorage("appointment_date");
      removeFromSessionStorage("payment_method");
      removeFromSessionStorage("want_invoice");
      removeFromSessionStorage("invoice_data");
    },
    clearPatients(store) {
      store.patients = [];
      removeFromSessionStorage("patients_data");
    },
    clearPersonalData(store) {
      store.personal_data = initPersonalData;
      store.address = initAddresData;
      removeFromSessionStorage("personal_data");
      removeFromSessionStorage("address");
    },
  },
  actions: {
    async checkZone({ commit, getters, rootGetters }) {
      const cityZone = rootGetters["cart/getSelectedCityZone"];
      const isHomeService = rootGetters["cart/isHomeService"];

      if (cityZone) {
        commit("setZoneId", cityZone);
      } else if (isHomeService && getters.getAddressData.zip_code != "") {
        try {
          const { data } = await http.get(
            `city/zone/${getters.getAddressData.zip_code}`
          );
          commit("setZoneId", data.zone_id);
        } catch (error) {
          commit("setZoneId", null);
        }
      }
    },
    createOrUpdatePatient({ commit, getters, dispatch }, patient) {
      const storedPatient = getters.getPatientByPhone(patient.national_number);

      let newPatientData = pick(patient, [
        "birth_date",
        "country",
        "country_code",
        "first_name",
        "last_name",
        "pesel",
      ]);
      newPatientData = {
        ...newPatientData,
        isNoPesel: patient.pesel ? false : true,
        sex: patient.gender,
        phone_number: patient.national_number,
        age: "adult",
      };

      if (storedPatient) {
        commit("updatePatient", {
          id: storedPatient.id,
          patient: newPatientData,
        });
      } else {
        commit("savePatient", {
          id: patient.id,
          ...newPatientData,
        });

        if (getters.getPatients.length === 1) {
          dispatch("cart/assignCartToPatients", [patient.id], {
            root: true,
          });
        }
      }
    },
    updatePatientById({ commit }, { id, patient }) {
      let newPatientData = pick(patient, [
        "birth_date",
        "country",
        "country_code",
        "first_name",
        "last_name",
        "pesel",
      ]);

      newPatientData = {
        ...newPatientData,
        isNoPesel: patient.pesel ? false : true,
        sex: patient.gender,
        phone_number: patient.national_number,
        age: "adult",
      };

      commit("updatePatient", { id, patient: newPatientData });
    },
    setUserDefaultData({ commit, rootGetters }) {
      const currentUser = rootGetters["user/getCurrentUser"];

      commit("setAddressData", rootGetters["user/getAddress"]);
      commit("setPersonalData", {
        country: currentUser.country,
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        national_phone_number: currentUser.national_number,
        phone_number_country: currentUser.country,
        phone_number_country_calling_code: currentUser.country_code,
      });
    },
    updateAddressData({ commit }, payload) {
      commit("setAddressData", payload);
    },
    updatePersonalData({ commit }, payload) {
      commit("setPersonalData", payload);
    },
    saveToSessionStorage({ state }) {
      saveToSessionStorage("address", state.address);
      saveToSessionStorage("personal_data", state.personal_data);
      saveToSessionStorage("want_invoice", state.wantInvoice);
      saveToSessionStorage("invoice_data", state.invoiceData);
    },
    loadDefaultInvoiceData({ commit, rootGetters }) {
      const firstInvoiceData = rootGetters["user/getInvoicesData"][0];
      const user = rootGetters["user/getCurrentUser"];

      if (user && firstInvoiceData) {
        commit("setInvoiceDataSilent", {
          first_name: user.first_name,
          last_name: user.last_name,
          ...firstInvoiceData,
        });
      } else if (user) {
        const address = rootGetters["user/getAddress"];

        commit("setInvoiceDataSilent", {
          first_name: user.first_name,
          last_name: user.last_name,
        });

        if (address) {
          commit("setInvoiceDataSilent", {
            street: address.street,
            home_number: address.home_number,
            premises_number: address.premises_number,
            zip_code: address.zip_code,
            city: address.city,
            additonal_comments: address.additonal_comments,
          });
        }
      }
    },
    loadDefaultInvoicePersonalData({ commit, rootGetters }) {
      const user = rootGetters["user/getCurrentUser"];

      if (user) {
        commit("setInvoiceDataSilent", {
          first_name: user.first_name,
          last_name: user.last_name,
        });
      }
    },
    async payForExaminations({ state, rootGetters, commit }) {
      commit("clearErrors");

      const getPatietntExaminations =
        rootGetters["cart/getPatietntExaminations"];
      const getPatietntOwnPackageExaminations =
        rootGetters["cart/getPatietntOwnPackageExaminations"];

      const patientsWithExaminations = state.patients.map((patient) => {
        let examinations = getPatietntExaminations(patient.id).map(
          (examination) => ({ id: examination.id, price: examination.price })
        );

        const ownExaminations = getPatietntOwnPackageExaminations(
          patient.id
        ).map((examination) => ({
          id: examination.id,
          price: examination.price,
        }));

        return {
          ...patient,
          examinations: [...examinations, ...ownExaminations],
        };
      });

      return new Promise((resolve, reject) => {
        const data = {
          homeService: rootGetters["cart/isHomeService"],
          city_uuid: rootGetters["cart/getSelectedCity"]?.uuid,
          facility_id: rootGetters["cart/getSelectedFacility"]?.id,
          reservation_id: rootGetters["reservation/getReservationId"],
          appointment: state.appointmentDate,
          paymentMethod: state.paymentMethod,
          patients: patientsWithExaminations,
          examinations: rootGetters["cart/getCart"],
          discount: rootGetters["cart/getDiscountCode"],
          address: {
            ...state.personal_data,
            ...state.address,
          },
          wantInvoice: state.wantInvoice,
          invoiceData: state.invoiceData,
        };

        return http
          .post("/referrals/pay", data)
          .then(({ data }) => {
            commit("clearData");
            commit("cart/clearAll", null, { root: true });
            commit("cart/setDiscountCode", null, { root: true });
            commit("clearPatients");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              const arr = {};
              forEach(response.data.errors, (error, key) => {
                set(arr, key, error);
              });
              commit("setRequestError", arr);
              commit("setErrors", response.data.errors);
              return reject(response.data.errors);
            } else if (response && response.status === 403) {
              commit("setErrors", { without_register: response.data.message });
            }

            return reject(response.data);
          });
      });
    },
    async payForReferralExaminations(
      { state, rootGetters, commit },
      referralGroup
    ) {
      commit("clearErrors");
      const getPatietntExaminations =
        rootGetters["cart/getPatietntExaminations"];
      state.patients.map((patient) => {
        return {
          ...patient,
          examinations: getPatietntExaminations(patient.id).map(
            (examination) => ({ id: examination.id, price: examination.price })
          ),
        };
      });

      return new Promise((resolve, reject) => {
        const data = {
          homeService: rootGetters["cart/isHomeService"],
          city_uuid: rootGetters["cart/getSelectedCity"].uuid,
          facility_id: rootGetters["cart/getSelectedFacility"].id,
          examinations: rootGetters["cart/getCart"],
        };

        return http
          .post(
            `/referralGroups/save-referral-doctor-data/${referralGroup.id}`,
            data
          )
          .then(({ data }) => {
            commit("clearData");
            commit("cart/clearAll", null, { root: true });
            window.open(data.url, "_self");
            resolve();
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              const arr = {};
              forEach(response.data.errors, (error, key) => {
                set(arr, key, error);
              });
              commit("setRequestError", arr);
              commit("setErrors", response.data.errors);
              reject(response.data.errors);
            } else {
              reject(response.data);
            }
          });
      });
    },
  },
};
