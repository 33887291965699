import axios from "axios";
import acceptLanguage from "accept-language";
import { getFromSessionStorage } from "@/utils/localstorage";

acceptLanguage.languages(["en", "pl", "ua"]);

let token = getFromSessionStorage("authtoken", null);
let language =
  acceptLanguage.get(
    localStorage.getItem("language") || navigator.language.substring(0, 2)
  ) || "en";

export default axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000,
  useCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
    "X-Requested-With": "XMLHttpRequest",
    "Accept-Language": language,
    "App-Name": process.env.VUE_APP_NAME,
  },
});
