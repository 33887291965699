import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createHead, VueHeadMixin } from "@unhead/vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Notifications from "@kyvg/vue3-notification";
import VOtpInput from "vue3-otp-input";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { setupCalendar, Calendar } from "v-calendar";
import { MonthPicker, MonthPickerInput } from "vue-month-picker";
import Datepicker from "@vuepic/vue-datepicker";

import vClickOutside from "click-outside-vue3";
import i18n from "./utils/i18n";

import fetchMixin from "@/mixins/fetchMixin";
import breadcrumbsMixin from "@/mixins/breadcrumbsMixin";
import landingPageMixin from "@/mixins/landingPageMixin";

import "@/assets/styles/index.scss";
import "@/assets/styles/slider.scss";
import "vue-loading-overlay/dist/vue-loading.css";
import "maz-ui/css/main.css";
import "@/assets/styles/number-phone-input.scss";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/styles/date-picker.scss";

import "@/utils/websocket.js";

const app = createApp(App);

app.config.globalProperties.currency = "zł";

app.use(i18n);
app.use(store);
app.use(router);
app.use(Notifications);
app.use(setupCalendar, {});
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  },
});
app.use(MonthPicker);
app.use(MonthPickerInput);
app.use(vClickOutside);

app.use(
  createGtm({
    id: process.env.VUE_APP_GTM,
    compatibility: true,
    enabled: process.env.VUE_APP_STATE === "PROD",
    loadScript:
      process.env.VUE_APP_STATE === "PROD" &&
      window.location === window.parent.location,
    vueRouter: router,
  })
);

app.use(createHead());

app.mixin(fetchMixin);
app.mixin(breadcrumbsMixin);
app.mixin(landingPageMixin);
app.mixin(VueHeadMixin);

app.component("Calendar", Calendar);
app.component("Datepicker", Datepicker);
app.component("v-otp-input", VOtpInput);

app.mount("#app");
