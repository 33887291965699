export default {
  namespaced: true,
  state: {
    mobile: false,
    runTradedoublerContainer: true,
  },
  getters: {
    isMobile: (state) => state.mobile,
    runTradedoublerContainer: (state) => state.runTradedoublerContainer,
  },
  mutations: {
    setMobile(state, isMobile) {
      state.mobile = isMobile;
    },
    setRunTradedoublerContainer(state, value) {
      state.runTradedoublerContainer = value;
    },
  },
};
