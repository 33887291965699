import http from "@/utils/axios";
import {
  getFromSessionStorage,
  saveToSessionStorage,
} from "../../utils/localstorage";

export default {
  namespaced: true,
  state: {
    nameCategory: [],
    examinations: [],
    packages: [],
    forOwnExaminations: [],
    popularExaminations: [],
    popularPackages: [],
    categories: [],
    searchExaminations: [],
    searchBundles: [],
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    packagePagination: {
      currentPage: 1,
      total: 1,
    },
    search: "",
    services: getFromSessionStorage("services", {
      covid_nursing_service: null,
      nursing_service: null,
      mobile_nursing_service: null,
    }),
    loadingServices: false,
    loadingPopular: false,
    loadingCategories: false,
  },
  getters: {
    getNameCategory: (state) => state.nameCategory,
    getExaminations: (state) => state.examinations,
    getPackages: (state) => state.packages,
    getForOwnExaminations: (state) => state.forOwnExaminations,
    getPopularExaminations: (state) => state.popularExaminations,
    getPopularPackages: (state) => state.popularPackages,
    getCategories: (state) => state.categories,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getPackagePagination: (state) => state.packagePagination,
    getSearch: (state) => state.search,
    getNursingServices: (state) => state.services,
    getUpielNursingServices: (state) => state.services.nursing_service,
    getMobileNursingServices: (state) => state.services.mobile_nursing_service,
    getSearchExaminations: (state) => state.searchExaminations,
    getSearchBundles: (state) => state.searchBundles,
    getBasicOwnExaminations: (state) =>
      state.forOwnExaminations.filter(
        (examination) => examination.own_package_type == "basic"
      ),
    getProfiledOwnExaminations: (state) =>
      state.forOwnExaminations.filter(
        (examination) => examination.own_package_type == "profiled"
      ),
    isLoadingServices: (state) => state.loadingServices,
    isLoadingPopular: (state) => state.loadingPopular,
    isLoadingCategories: (state) => state.loadingCategories,
  },
  mutations: {
    setExaminations(
      state,
      { nameCategory, examinations, packages, forOwnExaminations }
    ) {
      state.nameCategory = nameCategory;
      state.examinations = examinations.data;
      state.pagination.currentPage = examinations?.meta?.current_page;
      state.pagination.total = examinations?.meta?.last_page;
      state.packages = packages.data;
      state.packagePagination.currentPage = packages?.meta?.current_page;
      state.packagePagination.total = packages?.meta?.last_page;
      state.forOwnExaminations = forOwnExaminations.data;
    },
    setLoadingServices(state, isLoading) {
      state.loadingServices = isLoading;
    },
    setLoadingPopular(state, isLoading) {
      state.loadingPopular = isLoading;
    },
    setLoadingCategories(state, isLoading) {
      state.loadingCategories = isLoading;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setPopularExaminations(state, examinations) {
      state.popularExaminations = examinations;
    },
    setPopularPackages(state, packages) {
      state.popularPackages = packages;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPackagePage(state, currentPage) {
      state.packagePagination.currentPage = currentPage;
    },
    setNursingServices(state, services) {
      state.services.covid_nursing_service = services[0];
      state.services.nursing_service = services[1];
      state.services.mobile_nursing_service = services[2];
      saveToSessionStorage("services", state.services);
    },
    clearSearch(state) {
      state.search = "";
      state.searchExaminations = [];
      state.searchBundles = [];
    },
    clearAll(state) {
      state.examinations = [];
      state.packages = [];
      state.popularExaminations = [];
      state.errors = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
      state.packagePagination.currentPage = 1;
      state.packagePagination.total = 1;
      state.search = "";
    },
  },
  actions: {
    async fetchNursingServices({ commit, rootGetters }) {
      const isHomeService = rootGetters["cart/isHomeService"];
      const cityUuid = rootGetters["cart/getSelectedCity"]?.uuid;
      const facilityId = rootGetters["cart/getSelectedFacility"]?.id;

      const { data } = await http.get("services/nursing-services", {
        params: {
          city_uuid: isHomeService ? cityUuid : null,
          facility_id: !isHomeService ? facilityId : null,
        },
      });

      commit("setNursingServices", [
        data.covid_nursing_service,
        data.nursing_service,
        data.mobile_nursing_service,
      ]);
      commit(
        "cart/setNursingServices",
        [
          data.covid_nursing_service,
          data.nursing_service,
          data.mobile_nursing_service,
        ],
        {
          root: true,
        }
      );
    },
    async fetchCategories(
      { commit, rootGetters },
      { id = null } = { id: null }
    ) {
      const isSelectedBundles = rootGetters["cart/getIsSelectedBundles"];
      const type = isSelectedBundles ? "packages" : "examinations";

      try {
        commit("setLoadingCategories", true);
        const { data } = await http.get(`categories/${type}`, {
          params: {
            category_id: id,
          },
        });

        commit("setCategories", data);
        commit("setLoadingCategories", false);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchExaminations(
      { commit, dispatch, state, rootGetters },
      { id = null } = { id: null }
    ) {
      const isHomeService = rootGetters["cart/isHomeService"];
      const isSelectedBundles = rootGetters["cart/getIsSelectedBundles"];
      const isOwnBundles = rootGetters["cart/getIsSelectedOwnPackages"];
      const cityUuid = rootGetters["cart/getSelectedCity"]?.uuid;
      const facilityId = rootGetters["cart/getSelectedFacility"]?.id;

      try {
        commit("setLoadingServices", true);
        const { data } = await http.get("referrals/get-examinations", {
          params: {
            api_id: id,
            is_bundles: isSelectedBundles,
            is_own: isOwnBundles,
            page: state.pagination.currentPage,
            search: state.search,
            package_page: state.packagePagination.currentPage,
            city_uuid: isHomeService ? cityUuid : null,
            facility_id: !isHomeService ? facilityId : null,
          },
        });

        commit("setExaminations", data);
        dispatch("cart/initAdditionalServices", null, {
          root: true,
        });
        dispatch("cart/calculatePrice", null, {
          root: true,
        });
        commit("setLoadingServices", false);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPopularServices({ commit, rootGetters }, type = null) {
      const isHomeService = rootGetters["cart/isHomeService"];
      const cityUuid = rootGetters["cart/getSelectedCity"]?.uuid;
      const facilityId = rootGetters["cart/getSelectedFacility"]?.id;

      if (!type) {
        type = rootGetters["cart/getIsSelectedBundles"]
          ? "packages"
          : "examinations";
      }

      try {
        commit("setLoadingPopular", true);
        const { data } = await http.get(`services/popular/${type}`, {
          params: {
            city_uuid: isHomeService ? cityUuid : null,
            facility_id: !isHomeService ? facilityId : null,
          },
        });
        if (type === "packages") {
          commit("setPopularPackages", data);
        } else {
          commit("setPopularExaminations", data);
        }
        commit("setLoadingPopular", false);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
