import Echo from "laravel-echo";

if (process.env.VUE_APP_ECHO === "true") {
  window.Pusher = require("pusher-js");
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_WSKEY,
    wsHost: process.env.VUE_APP_WSHOST,
    cluster: process.env.VUE_APP_WSCLUSTER,
    wsPort: process.env.VUE_APP_WSPORT,
    forceTLS: false,
    disableStats: true,
    encrypted: true,
    authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
  });
}
