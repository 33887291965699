<template>
  <TransitionRoot as="template" :show="isOpen(modalName)">
    <Dialog
      as="div"
      static
      class="fixed z-50 inset-0 overflow-y-auto"
      :open="isOpen(modalName)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay
          class="fixed inset-0 backdrop-blur-[20px] transition-opacity"
        />
      </TransitionChild>

      <div class="flex items-center justify-center h-screen">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            :class="[maxWidth, hideOverflow ? 'overflow-hidden' : '']"
            class="my-auto flex w-full items-center justify-center px-4 py-10 md:px-5"
          >
            <BoxWrapper
              :class="[maxWidth, paddingsClass]"
              :theme="theme"
              class="inline-block w-full transform align-middle !shadow-md transition-all"
            >
              <button
                v-if="closeButton"
                class="transition-ease-in-out absolute -right-2 -top-5 flex h-10 w-10 items-center justify-center rounded-full border border-white bg-primary text-white hover:border-primary hover:bg-white hover:text-primary md:-right-5"
                @click="closeModal(modalName)"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.9">
                    <path
                      d="M15.5771 5.33691L5.67765 15.2364"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.67725 5.33447L15.5767 15.234"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </button>

              <slot />
            </BoxWrapper>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import BoxWrapper from "@/components/Core/BoxWrapper.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "CoreModalWrapper",
  components: {
    BoxWrapper,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  emits: ["opened", "closed"],
  props: {
    modalName: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "",
    },
    paddingsClass: {
      type: [String],
      default: "px-4 py-10 md:px-12",
    },
    hideOverflow: {
      type: [Boolean],
      default: true,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: "white",
    },
  },
  watch: {
    isOpenModal(newValue) {
      if (newValue) {
        this.$emit("opened");
      } else {
        this.$emit("closed");
      }
    },
  },
  computed: {
    isOpenModal() {
      return this.isOpen(this.modalName);
    },
    ...mapGetters({
      isOpen: "modals/isOpenModal",
    }),
  },
  methods: {
    ...mapMutations({
      closeModal: "modals/closeModal",
      openModal: "modals/openModal",
    }),
  },
};
</script>
