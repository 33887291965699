import http from "@/utils/axios";
import router from "@/router";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    referrals: [],
    referralGroups: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    currentReferral: null,
    stats: {
      referrals_count: 0,
      avg_referrals_count: 0,
      avg_referrals_price: 0,
    },
    commissionRange: [1, 100],
    priceRange: [1, 1000],
  },
  getters: {
    getReferrals: (state) => state.referrals,
    getReferralById: (state) => (id) => {
      return state.referrals.find((referral) => referral.id === id);
    },
    getPagination: (state) => state.pagination,
    getCurrentReferral: (state) => state.currentReferral,
    getStats: (state) => state.stats,
    getCommissionRange: (state) => state.commissionRange,
    getPriceRange: (state) => state.priceRange,
    getReferralGroups: (state) => state.referralGroups,
  },
  mutations: {
    setReferralGroups(state, referralGroups) {
      state.referralGroups = referralGroups;
    },
    pushToReferralGroups(state, referralGroup) {
      state.referralGroups = [referralGroup, ...state.referralGroups];
    },
    updateReferralGroups(state, referralGroup) {
      state.referralGroups = state.referralGroups.map((referral) =>
        referral.id === referralGroup.id ? referralGroup : referral
      );
    },
    setReferrals(state, referrals) {
      state.referrals = referrals.data;
      state.pagination.currentPage = referrals.meta.current_page;
      state.pagination.total = referrals.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentReferral(state, currentReferral) {
      state.currentReferral = currentReferral;
    },
    setCurrentReferralPatient(state, patient) {
      state.currentReferral.patient = patient;
    },
    setStats(state, stats) {
      state.stats = stats;
    },
    setCommissionRange(state, commissionRange) {
      state.commissionRange = commissionRange;
    },
    setPriceRange(state, priceRange) {
      state.priceRange = priceRange;
    },
    clearReferrals(state) {
      state.referrals = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    clearCurrentReferral(state) {
      state.currentReferral = null;
    },
    setPagination(state, { currentPage, total }) {
      state.pagination.currentPage = currentPage;
      state.pagination.total = total;
    },
  },
  actions: {
    async fetchReferrals(
      { commit, state, rootGetters },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get("referrals", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
      commit("user/setCurrentUserStats", data.stats, { root: true });
      let userRole = rootGetters["user/getCurrentUserRole"];
      if (userRole != "patient")
        commit("referrals/setCommissionRange", data.commission_range, {
          root: true,
        });
    },
    async checkResults({ commit, state }, id) {
      const { data } = await http.get(`referrals/check-results/${id}`, {
        params: {
          page: state.pagination.currentPage,
        },
      });
      commit("setPagination", {
        currentPage: data.meta.current_page,
        total: data.meta.last_page,
      });
      commit("setReferralGroups", data.data);
    },
    async fetchReferralGroups({ commit, state }) {
      const { data } = await http.get(`referralGroups`, {
        params: {
          page: state.pagination.currentPage,
          timestamp: Date.now(),
        },
      });
      commit("setPagination", {
        currentPage: data.meta.current_page,
        total: data.meta.last_page,
      });
      commit("setReferralGroups", data.data);
    },
    async fetchAdminReferrals(
      { commit, state },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get("admin/referrals", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
      commit("setStats", data.stats);
      commit("doctors/setTopDoctors", data.top_doctors, { root: true });
      commit("referrals/setPriceRange", data.price_range, {
        root: true,
      });
    },
    async fetchCurrentReferral({ commit }, id) {
      return await http
        .get(`/referrals/${id}`)
        .then(({ data }) => {
          commit("setCurrentReferral", data.referral);
        })
        .catch(async ({ response }) => {
          if ([401, 403].includes(response?.status)) {
            await router.push({
              name: "ErrorCode",
              params: { code: response?.status },
            });
            router.go(0);
          }
        });
    },
    async sendAndDownloadReferral({ state }) {
      return await http({
        url: `/referrals/send-and-download/${state.currentReferral.id}`,
        method: "POST",
      })
        .then((response) => {
          window.open(response.data.url, "_blank");
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
