import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters({ isMobile: "app/isMobile" }),
  },
  methods: {
    onResize() {
      const isMob = window.innerWidth < 1024;
      this.setMobile(isMob);

      if (!isMob) {
        window.scrollTo(0, 0);
      }
    },
    ...mapMutations({
      setMobile: "app/setMobile",
    }),
  },
  mounted() {
    if (!window.isMobileInit) {
      window.isMobileInit = true;
      this.onResize();
      window.addEventListener("resize", this.onResize);
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize);
    window.isMobileInit = false;
  },
};
