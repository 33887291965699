import { mapMutations } from "vuex";

export default {
  methods: {
    async callFetch() {
      if (fetchExists(this)) {
        this.contentIsLoading();
        await this.$options.fetch.call(this);
        this.contentLoaded();
      }
    },
    ...mapMutations({
      contentIsLoading: "loader/contentIsLoading",
      contentLoaded: "loader/contentLoaded",
    }),
  },
  async created() {
    await this.callFetch();
  },
};

const fetchExists = (vm) => {
  return vm.$options && typeof vm.$options.fetch === "function";
};
