import * as CryptoJS from "crypto-js";

export function removeFromSessionStorage(key) {
  sessionStorage.removeItem(key);
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export function saveEncryptedToSessionStorage(key, data) {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.VUE_APP_CRYPTO_SECRET
  ).toString();

  sessionStorage.setItem(key, encryptedData);
}

export function saveToSessionStorage(key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function saveToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getFromSessionStorage(key, defaultValue = []) {
  return getFromStorage(sessionStorage, key, defaultValue);
}

export function getDecryptedFromSessionStorage(key, defaultValue = []) {
  return getDecryptedFromStorage(sessionStorage, key, defaultValue);
}

export function getFromLocalStorage(key, defaultValue = []) {
  return getFromStorage(localStorage, key, defaultValue);
}

export const getFromStorage = (storage, key, defaultValue = []) => {
  try {
    if (storage.getItem(key)) return JSON.parse(storage.getItem(key));
  } catch (e) {
    return defaultValue;
  }
  return defaultValue;
};

export const getDecryptedFromStorage = (storage, key, defaultValue = []) => {
  try {
    let data;

    if ((data = storage.getItem(key))) {
      const decryptedData = CryptoJS.AES.decrypt(
        data,
        process.env.VUE_APP_CRYPTO_SECRET
      ).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decryptedData);
    }
  } catch (e) {
    return defaultValue;
  }
  return defaultValue;
};
