import http from "@/utils/axios";
import store from "@/store";
import router from "@/router/index.js";
import { notify } from "@kyvg/vue3-notification";
import {
  getFromSessionStorage,
  getFromLocalStorage,
  removeFromSessionStorage,
  removeFromLocalStorage,
  saveToSessionStorage,
  saveToLocalStorage,
} from "@/utils/localstorage";

export default {
  namespaced: true,
  state: {
    cart: getFromLocalStorage("cart", []),
    selectedExamination: null,
    additionalServices: getFromLocalStorage("additional_services", null),
    errors: [],
    totalPrice: 0,
    savings: 0,
    commission: 0,
    patientDiscount: 0,
    patientDiscountPercent: 0,
    mobile_nursing_service: getFromSessionStorage(
      "mobile_nursing_service",
      null
    ),
    covid_nursing_service: getFromSessionStorage("covid_nursing_service", null),
    nursing_service: getFromSessionStorage("nursing_service", null),
    cities: null,
    selectedCity: getFromSessionStorage("selected_city", null),
    homeService: getFromSessionStorage("home_service", false),
    selectedFacility: getFromLocalStorage("selected_facility", null),
    isSelectedBundles: false,
    isSelectedOwnPackages: false,
    validateFormPatientDetalisInvoice: false,
    validateForm: false,
    acceptFacilityHome: false,
    validPostalCode: true,
    discountCode: getFromSessionStorage("discount_code", null),
  },
  getters: {
    getCart: (state) => state.cart,
    getDiscount: (state) => (patientId) => {
      const examCount = state.cart.filter(
        (examination) =>
          examination.patients.includes(patientId) &&
          examination.for_own_package
      ).length;
      let discount = 0;

      if (examCount < 5) return discount;
      if (examCount >= 5) discount = 5;
      if (examCount > 15) discount = 25;

      if (examCount > 5 && examCount <= 15) {
        discount += 2 * (examCount - 5);
      }

      return discount;
    },
    getDiscountForAll: (state) => {
      const examCount = state.cart.filter(
        (examination) => examination.for_own_package
      ).length;
      let discount = 0;

      if (examCount < 5) return discount;
      if (examCount >= 5) discount = 5;
      if (examCount > 15) discount = 25;

      if (examCount > 5 && examCount <= 15) {
        discount += 2 * (examCount - 5);
      }

      return discount;
    },
    getNextDiscount: (state) => (patientId) => {
      const examCount = state.cart.filter(
        (examination) =>
          examination.patients.includes(patientId) &&
          examination.for_own_package
      ).length;

      let discount = 5;
      let quantity = null;

      if (examCount < 5) {
        return {
          discount,
          quantity: 5 - examCount,
        };
      }

      quantity = 1;
      discount += 2 * (examCount - 5) + 2;

      if (discount > 25) {
        return null;
      }

      return {
        discount,
        quantity,
      };
    },
    getNextDiscountAll: (state) => {
      const examCount = state.cart.filter(
        (examination) => examination.for_own_package
      ).length;

      let discount = 5;
      let quantity = null;

      if (examCount < 5) {
        return {
          discount,
          quantity: 5 - examCount,
        };
      }

      quantity = 1;
      discount += 2 * (examCount - 5) + 2;

      if (discount > 25) {
        return null;
      }

      return {
        discount,
        quantity,
      };
    },
    getExaminations: (state) => {
      return state.cart.filter(
        (examination) =>
          examination.added_by_doctor == false && !examination.for_own_package
      );
    },
    getPatietntExaminations: (state) => (patientId) => {
      return state.cart.filter(
        (examination) =>
          examination.patients.includes(patientId) &&
          examination.added_by_doctor == false &&
          !examination.for_own_package
      );
    },
    getPatietntExaminationsAddedByDoctor: (state) => (patientId) =>
      state.cart.filter(
        (examination) =>
          examination.patients.includes(patientId) &&
          examination.added_by_doctor == true &&
          !examination.for_own_package
      ),
    getOwnPackageExaminations: (state) =>
      state.cart.filter((examination) => examination.for_own_package),
    getOwnPackages: (state, getters) => (patients) => {
      let ownPackages = [];

      patients.forEach((patient) => {
        const ownExaminations = state.cart.filter(
          (examination) =>
            examination.patients.includes(patient.id) &&
            examination.for_own_package
        );
        const patientDiscount = getters.getDiscount(patient.id);

        if (ownExaminations.length > 0) {
          const totalPrice = ownExaminations
            .reduce((total, exam) => {
              const discountedPrice =
                exam.price - (exam.price * patientDiscount) / 100;
              return total + Math.round(discountedPrice * 100) / 100;
            }, 0)
            .toFixed(2);

          ownPackages = [
            ...ownPackages,
            {
              patient,
              examinaitons: ownExaminations,
              totalPrice,
            },
          ];
        }
      });

      return ownPackages;
    },
    getPatietntOwnPackageExaminations: (state) => (patientId) =>
      state.cart.filter(
        (examination) =>
          examination.patients.includes(patientId) &&
          examination.for_own_package
      ),
    getExaminationIds: (state) => state.cart.map((item) => item.id),
    getExaminationPatients: (state) => (examinationId) =>
      state.cart.find((examinaiton) => examinaiton.id === examinationId)
        ?.patients ?? [],
    getExaminationAddedByDoctor: (state) => (id) => {
      const item = state.cart.find((item) => item.id === id);
      if (typeof item !== "undefined") {
        return item.added_by_doctor;
      }
      return 0;
    },
    getPatientHasExamination: (state) => (examinationId, patientId) => {
      return (
        state.cart.filter(
          (examinaiton) =>
            examinaiton.id === examinationId &&
            examinaiton.patients.includes(patientId)
        ).length > 0
      );
    },
    getErrors: (state) => state.errors,
    getTotalPrice: (state) => state.totalPrice,
    getSavings: (state) => state.savings,
    getCommission: (state) => state.commission,
    getCartAddedByDoctor: (state) =>
      state.cart.filter((item) => item.added_by_doctor),
    getCartAddedByPatient: (state) =>
      state.cart.filter((item) => !item.added_by_doctor),
    getAdditionalServices: (state) => state.additionalServices,
    getPatientDiscount: (state) => state.patientDiscount,
    getAllCities: (state) => state.cities,
    getSelectedCity: (state) => state.selectedCity,
    getSelectedCityZone: (state) => state.selectedCity?.zone_id,
    getSelectedExamination: (state) => state.selectedExamination,
    hasExaminations: (state) => state.cart.length,
    existExaminationId: (state) => (id) =>
      state.cart.findIndex((examination) => examination.id === id) !== -1,
    isHomeService: (state) => state.homeService,
    isValidPostalCode: (state) => state.validPostalCode,
    getIsSelectedBundles: (state) => state.isSelectedBundles,
    getIsSelectedOwnPackages: (state) => state.isSelectedOwnPackages,
    getSelectedFacility: (state) => state.selectedFacility,
    getHomeService: (state) => state.selectedFacility,
    getValidateFormPatientDetalisInvoice: (state) =>
      state.validateFormPatientDetalisInvoice,
    getAcceptFacilityHome: (state) => state.acceptFacilityHome,
    isSelectedCityOrPoint: (state) =>
      (state.homeService && state.selectedCity != null) ||
      (!state.homeService && state.selectedFacility != null),
    getDiscountCode: (state) => state.discountCode,
    getDiscountedProducts: (state) =>
      state.discountCode?.productsDiscounts ?? [],
  },
  mutations: {
    setCart(store, cart) {
      store.cart = cart;
    },
    setTotal(store, total) {
      store.totalPrice = total;
    },
    setSavings(store, amount) {
      store.savings = amount;
    },
    setCartItem(store, { index, item }) {
      store.cart[index] = { ...item };
    },
    setSelectedExamination(state, examination) {
      state.selectedExamination = { ...examination };
    },
    clearSelectedExamination(state) {
      state.selectedExamination = null;
    },
    setNursingServices(state, services) {
      state.covid_nursing_service = services[0];
      state.nursing_service = services[1];
      state.mobile_nursing_service = services[2];
      saveToSessionStorage("covid_nursing_service", services[0]);
      saveToSessionStorage("nursing_service", services[1]);
      saveToSessionStorage("mobile_nursing_service", services[2]);
    },
    setAdditionalServices(state, services) {
      state.additionalServices = services;
      saveToLocalStorage("additional_services", state.additionalServices);
    },
    clearAdditionalServices(state) {
      state.additionalServices = null;
      localStorage.removeItem("additional_services");
    },
    clearCart(store) {
      store.cart = [];
    },
    clearAll(state) {
      state.cart = [];
      state.totalPrice = 0;
      state.commission = 0;
      state.errors = [];
      state.additionalServices = null;

      removeFromLocalStorage("cart");
      removeFromLocalStorage("additional_services");
      removeFromSessionStorage("covid_nursing_service");
      removeFromSessionStorage("nursing_service");
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setPatientDiscountPercent(state, patientDiscountPercent) {
      state.patientDiscountPercent = patientDiscountPercent;
    },
    setHomeService(state, homeService) {
      state.homeService = homeService;
      saveToSessionStorage("home_service", state.homeService);
    },
    setValidPostalCode(state, isValid) {
      state.validPostalCode = isValid;
    },
    setAllCities(state, cities) {
      state.cities = cities;
    },
    setIsSelectedBundles(state, isSelected) {
      state.isSelectedBundles = isSelected;
    },
    setIsSelectedOwnPackages(state, isSelected) {
      state.isSelectedOwnPackages = isSelected;
    },
    setSelectedCity(state, city) {
      state.selectedCity = city;
      saveToSessionStorage("selected_city", state.selectedCity);
    },
    setSelectedFacility(state, facility) {
      state.selectedFacility = facility;
      saveToLocalStorage("selected_facility", state.selectedFacility);
    },
    setExaminationPrice(state, { index, price }) {
      state.cart[index].price = price;
    },
    removeFromCart(state, patientId) {
      state.cart = state.cart.map((examination) => {
        examination.patients = examination.patients.filter(
          (id) => id !== patientId
        );
        return examination;
      });
    },
    clearEmptyCartExaminations(state) {
      state.cart = state.cart.filter(
        (examination) => examination.patients.length > 0
      );
    },
    setValidateFormPatientDetalisInvoice(state, isNeeded) {
      state.validateFormPatientDetalisInvoice = isNeeded;
    },
    setAcceptFacilityHome(state, isAccepted) {
      state.acceptFacilityHome = isAccepted;
    },
    setDiscountCode(state, discountCode) {
      state.discountCode = discountCode;
      saveToSessionStorage("discount_code", state.discountCode);
    },
  },
  actions: {
    calculatePrice({ state, getters, commit, rootGetters }) {
      let totalPrice = 0;
      let totalPriceNoDiscount = 0;
      const patients = rootGetters["checkout/getPatients"];

      if (patients.length > 0) {
        patients.forEach(({ id }) => {
          let examinations = getters.getPatietntExaminations(id);
          examinations = examinations.filter((exam) => exam.available);
          let doctorExaminations =
            getters.getPatietntExaminationsAddedByDoctor(id);
          doctorExaminations = doctorExaminations.filter(
            (exam) => exam.available
          );
          totalPrice += examinations.reduce(
            (accumulator, current) => accumulator + current.price * 1,
            0
          );
          totalPriceNoDiscount += examinations.reduce(
            (accumulator, current) => accumulator + current.price * 1,
            0
          );

          let ownPackageExams = getters.getPatietntOwnPackageExaminations(id);
          ownPackageExams = ownPackageExams.filter((exam) => exam.available);
          const discount = getters.getDiscount(id);
          totalPrice += ownPackageExams.reduce((accumulator, current) => {
            if (discount > 0) {
              return (
                accumulator +
                Math.round(
                  (current.price - (current.price * discount) / 100) * 100
                ) /
                  100
              );
            } else {
              return accumulator + current.price * 1;
            }
          }, 0);
          totalPriceNoDiscount += ownPackageExams.reduce(
            (accumulator, current) => {
              return accumulator + current.price * 1;
            },
            0
          );

          totalPrice += doctorExaminations.reduce(
            (accumulator, current) => accumulator + current.price * 1,
            0
          );
          totalPriceNoDiscount += doctorExaminations.reduce(
            (accumulator, current) => accumulator + current.price * 1,
            0
          );
        });
      } else {
        let examinations = getters.getExaminations;
        examinations = examinations.filter((exam) => exam.available);
        totalPrice += examinations.reduce(
          (accumulator, current) => accumulator + current.price * 1,
          0
        );
        totalPriceNoDiscount += examinations.reduce(
          (accumulator, current) => accumulator + current.price * 1,
          0
        );

        let ownPackageExams = getters.getOwnPackageExaminations;
        ownPackageExams = ownPackageExams.filter((exam) => exam.available);
        const discount = getters.getDiscountForAll;

        totalPrice += ownPackageExams.reduce((accumulator, current) => {
          if (discount > 0) {
            return (
              accumulator +
              Math.round(
                (current.price - (current.price * discount) / 100) * 100
              ) /
                100
            );
          } else {
            return accumulator + current.price * 1;
          }
        }, 0);
        totalPriceNoDiscount += ownPackageExams.reduce(
          (accumulator, current) => {
            return accumulator + Math.floor(current.price * 1);
          },
          0
        );
      }

      let additionalPrice = 0;

      if (
        state.additionalServices?.name === "upiel" &&
        totalPrice != 0 &&
        state.nursing_service?.price
      ) {
        additionalPrice +=
          state.nursing_service?.price * state.additionalServices?.quantity;
      }
      if (
        state.homeService === true &&
        state.cart.length > 0 &&
        totalPrice != 0
      ) {
        additionalPrice += state.mobile_nursing_service.price * 1;
      }

      if (state.discountCode) {
        totalPrice -= state.discountCode.discount;
      }

      commit("setTotal", totalPrice + additionalPrice);
      commit("setSavings", totalPriceNoDiscount - totalPrice);
    },
    updateSelectedCity({ commit, dispatch }, city) {
      commit("setSelectedCity", city);
      dispatch("checkout/checkZone", null, { root: true });
    },
    initAdditionalServices({ state, getters, commit, rootGetters }) {
      const nurse = state.cart.filter((item) => item.require_nursing_services);
      const covid = state.cart.filter(
        (item) => item.require_covid_nursing_services
      );
      let service = {};

      if (nurse.length > 0) {
        service.name = "upiel";
      } else if (covid.length > 0) {
        service.name = "upielc";
      }

      if (service) {
        if (getters.isHomeService) {
          service.quantity = 0;
        } else {
          const patients = rootGetters["checkout/getPatients"];
          let patientsWithExaminations = patients.filter((patient) => {
            return (
              getters.getPatietntOwnPackageExaminations(patient.id).length >
                0 || getters.getPatietntExaminations(patient.id).length > 0
            );
          }).length;

          service.quantity =
            patientsWithExaminations >= 1 ? patientsWithExaminations : 1;
        }
      }

      commit("setAdditionalServices", service);
      saveToLocalStorage("additional_services", state.additionalServices);
    },
    removePatientFromExaminations({ state, commit, dispatch }, patientId) {
      commit("removeFromCart", patientId);
      commit("clearEmptyCartExaminations");
      dispatch("initAdditionalServices");
      dispatch("calculatePrice");
      saveToLocalStorage("cart", state.cart);
    },
    assignCartToPatients({ state, commit, dispatch }, patients) {
      state.cart.forEach((item, index) => {
        commit("setCartItem", { index, item: { ...item, patients } });
      });

      saveToLocalStorage("cart", state.cart);
      commit("setCart", state.cart);
      dispatch("initAdditionalServices");
      dispatch("calculatePrice");
    },
    removeItemFromCart({ commit, dispatch }, itemId) {
      let cart = getFromLocalStorage("cart", []);
      const itemIndex = cart.findIndex((item) => item.id === itemId);

      if (itemIndex >= 0) {
        cart.splice(itemIndex, 1);
        saveToLocalStorage("cart", cart);
        commit("setCart", cart);
        dispatch("initAdditionalServices");
        dispatch("calculatePrice");
      }
    },
    manageCart({ commit, dispatch }, { examination, patients }) {
      let cart = getFromLocalStorage("cart", []);
      const index = cart.findIndex((item) => item.id === examination?.id);

      if (patients.length === 0 && index !== -1) {
        cart.splice(index, 1);
      } else if (index === -1) {
        cart = [
          {
            id: examination.id,
            service_id: examination.service_id,
            name: examination.name,
            price: examination.price,
            package: examination.package,
            require_nursing_services: examination.require_nursing_services,
            require_covid_nursing_services:
              examination.require_covid_nursing_services,
            for_own_package: examination.for_own_package,
            own_package_type: examination.own_package_type,
            slug: examination.slug,
            patients,
            available: true,
            added_by_doctor: examination?.added_by_doctor ?? false,
          },
          ...cart,
        ];
      } else {
        cart[index] = { ...cart[index], patients };
      }
      saveToLocalStorage("cart", cart);
      commit("setCart", cart);
      dispatch("initAdditionalServices");
      dispatch("calculatePrice");
    },
    manageCartAddedByDoctor({ commit, dispatch }, { examination, patients }) {
      let cart = getFromLocalStorage("cart", []);
      const index = cart.findIndex((item) => item.id === examination.id);
      if (patients.length === 0 && index !== -1) {
        cart.splice(index, 1);
      } else if (index === -1) {
        cart = [
          {
            id: examination.available_service_id,
            service_id: examination.service_id,
            name: examination.available_service.service.name,
            price: examination.price,
            package: examination.available_service.service.is_bundle,
            require_nursing_services:
              examination.available_service.service.require_nursing_services,
            require_covid_nursing_services:
              examination.available_service.service
                .require_covid_nursing_services,
            slug: examination.available_service.service.slug,
            patients,
            available: examination.available,
            added_by_doctor: examination.added_by_doctor,
          },
          ...cart,
        ];
      } else {
        cart[index] = { ...cart[index], patients };
      }

      saveToLocalStorage("cart", cart);
      commit("setCart", cart);
      dispatch("initAdditionalServices");
      dispatch("calculatePrice");
    },
    async fetchReferral({ commit, dispatch }, id) {
      return await http
        .get(`/referrals/edit/${id}`)
        .then(({ data }) => {
          saveToLocalStorage(
            "cart",
            data.referral.examinations.filter(
              (examination) => !examination.is_additional
            )
          );
          commit("referrals/setCurrentReferral", data.referral, { root: true });
          commit(
            "setCart",
            data.referral.examinations.filter(
              (examination) => !examination.is_additional
            )
          );
          dispatch("initAdditionalServices");
          commit(
            "setPatientDiscountPercent",
            data.referral.patient_discount_percent
          );
          dispatch("calculatePrice");
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async saveReferral({ commit, state }, user) {
      return await http
        .post(`/referrals/store/${user.id}`, {
          cart: state.cart,
          require_additional: state.additionalServices.name,
        })
        .then(() => {
          sessionStorage.removeItem("cart");
          commit("clearCart");
          commit("clearErrors");
          store.commit("modals/closeModal", "createPatient");
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({ name: "DoctorReferralHistory" });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.edit.failure",
            duration: 5000,
          });
        });
    },
    async updateReferral({ commit, state }, id) {
      return await http
        .put(`/referrals/update/${id}`, {
          cart: state.cart,
          require_additional: state.additionalServices.name,
        })
        .then(() => {
          store.commit("modals/closeModal", "referralEditWarning");
          router.push({ name: "DoctorReferralHistory" });
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.edit.failure",
            duration: 5000,
          });
        });
    },

    async payForReferral({ commit, state }, id) {
      return await http
        .post(`/referrals/pay/${id}`, {
          cart: state.cart,
        })
        .then(({ data }) => {
          window.open(data.url, "_self");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          let message = "";
          switch (response.status) {
            case 404:
              message = "messages.payment_token.failure";
              break;
            case 403:
              message = "messages.permission.failure";
              break;
            default:
              message = "messages.edit.failure";
              break;
          }
          notify({
            type: "danger",
            text: message,
            duration: 5000,
          });
        });
    },
    async fetchCities({ commit, dispatch }) {
      const response = await http.get("/get-cities");
      const cities = response.data.cities;
      commit("setAllCities", cities);
      dispatch("checkCurrentCityExist");
    },
    async fetchUpdatedExaminationsPrices(state, payload) {
      return await http.post("updated/examinations-data", payload);
    },
    async updateIsHomeservice({ commit, dispatch, getters }, payload) {
      commit("setHomeService", payload);

      if (getters.isSelectedCityOrPoint) {
        await dispatch("updateExaminationsPrices");
      }
    },
    async updateExaminationsPrices({ getters, dispatch }) {
      const { isHomeService } = getters;

      const { data } = await dispatch("fetchUpdatedExaminationsPrices", {
        examinaitons: getters.getExaminationIds,
        ...(isHomeService && { city_uuid: getters.getSelectedCity?.uuid }),
        ...(!isHomeService && { facility_id: getters.getSelectedFacility?.id }),
      });

      dispatch("updateCartItemsPrices", data);
    },
    updateCartItemsPrices({ state, commit }, data) {
      const tmpCart = [];
      state.cart.forEach((examinaiton) => {
        const newExamination = data.examinations[examinaiton.id];
        tmpCart.push({
          id: newExamination.id,
          service_id: newExamination.service_id,
          name: examinaiton.name,
          price: newExamination.price,
          net_price: newExamination.pricing.net_price,
          tax: newExamination.pricing.tax,
          tax_value: newExamination.pricing.tax_value,
          gross_price: newExamination.pricing.gross_price,
          package: examinaiton.package,
          require_nursing_services: examinaiton.require_nursing_services,
          require_covid_nursing_services:
            examinaiton.require_covid_nursing_services,
          slug: examinaiton.slug,
          patients: examinaiton.patients,
          for_own_package: examinaiton.for_own_package,
          own_package_type: examinaiton.own_package_type,
          available: newExamination.isAvailable,
          added_by_doctor: examinaiton.added_by_doctor,
        });
      });
      commit("setCart", tmpCart);
      saveToLocalStorage("cart", state.cart);
    },
    setUserDefaultFacility({ commit }, facility) {
      commit("setSelectedFacility", facility);
    },
    checkCurrentCityExist({ commit, getters }) {
      if (getters.getSelectedCity) {
        const currentCityIndex = getters.getAllCities.findIndex((city) => {
          return city.uuid == getters.getSelectedCity.uuid;
        });

        if (currentCityIndex === -1) {
          commit("setSelectedCity", null);
        }
      }
    },
    removeDoctorExaminaions({ getters, dispatch }) {
      const addedByDoctor = [...getters.getCartAddedByDoctor];

      addedByDoctor.forEach((exam) => {
        dispatch("manageCart", {
          examination: exam,
          patients: "",
        });
      });
    },
    async realizeDiscountCode({ getters, commit, dispatch }, discount_code) {
      return new Promise((resolve, reject) => {
        return http
          .post("/check-code", {
            discount_code,
            examinations: getters["getCart"],
          })
          .then(({ data }) => {
            commit("setDiscountCode", { ...data });
            dispatch("calculatePrice");
            resolve(data);
          })
          .catch((error) => {
            commit("setDiscountCode", null);
            reject(error);
          });
      });
    },
  },
};
