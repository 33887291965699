import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router/index.js";

export default {
  namespaced: true,
  state: {
    allFacilities: [],
    filteredFacilities: [],
    facilities: [],
    totalFacilities: 0,
    pagination: {
      currentPage: 1,
      total: 1,
    },
    search: "",
    location: null,
    filters: [],
  },
  getters: {
    getAllFacilities: (state) => state.allFacilities,
    getFilteredFacilities: (state) => state.filteredFacilities,
    getFacilities: (state) => state.facilities,
    getTotalFacilities: (state) => state.totalFacilities,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getLocation: (state) => state.location,
    getFilters: (state) => state.filters,
  },
  mutations: {
    setFacilities(state, facilities) {
      state.allFacilities = facilities;
      state.facilities = facilities.slice(0, 30);
      state.pagination.currentPage = 1;
      state.pagination.total = Math.ceil(facilities.length / 30);
    },
    setTotalFacilities(state, total) {
      state.totalFacilities = total;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setLoaction(state, location) {
      state.location = location;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setAllFacilities(state, allFacilities) {
      state.allFacilities = allFacilities;
    },
    setPartFacilities(state, facilities) {
      state.facilities = facilities;
    },
    setFilteredFacilities(state, filteredFacilities) {
      state.filteredFacilities = filteredFacilities;
      state.pagination.total = Math.ceil(filteredFacilities.length / 30);
    },
    clearAll(state) {
      state.allFacilities = [];
      state.filteredFacilities = [];
      state.facilities = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
      state.search = "";
      state.location = null;
      state.filters = [];
    },
  },
  actions: {
    async fetchFacilities({ commit, getters }) {
      try {
        const { data } = await http.get("/get-facilities", {
          params: {
            search: getters.getSearch,
            lat: getters.getLocation?.latitude,
            long: getters.getLocation?.longitude,
          },
        });
        commit("setFacilities", data.facilities);
        commit("setFilteredFacilities", data.facilities);
        commit("setTotalFacilities", data.total);
      } catch (error) {
        commit("setFacilities", []);
      }
    },
    async fetchFacilitie(state, city) {
      const { data } = await http.get("/get-facilitie", {
        params: {
          city,
        },
      });

      return data.facilitie;
    },
    async fetchAvailableFacilities({ commit }, referralId) {
      const { data } = await http.get(`/referrals/edit-facility/${referralId}`);
      commit("setFacilities", data.facilities);
    },
    sliceFacilities({ state, commit }) {
      const page = state.pagination.currentPage;
      commit(
        "setPartFacilities",
        state.filteredFacilities.slice((page - 1) * 30, page * 30)
      );
    },
    filterFacilities({ state, commit, dispatch, getters }) {
      const filtered = state.allFacilities.filter((item) => {
        for (let key of getters.getFilters) {
          if (!item.features || !item?.features[key]) {
            return false;
          }
        }

        return true;
      });

      commit("setFilteredFacilities", filtered);
      dispatch("sliceFacilities");
    },
    async selectFacility(_, id) {
      return await http
        .post(`/register/choose-facility/${id}`)
        .then(() => {
          router.push({ name: "Examinations" });
        })
        .catch(() => {
          notify({
            type: "success",
            text: "messages.save.failure",
            duration: 5000,
          });
        });
    },
    async changeFacility(_, { referralId, facilityId }) {
      return await http
        .put(`/referrals/update-facility/${referralId}/${facilityId}`)
        .then(() => {
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({
            name: "PatientExaminations",
            params: { id: referralId },
          });
        })
        .catch(() => {
          notify({
            type: "success",
            text: "messages.save.failure",
            duration: 5000,
          });
        });
    },
  },
};
