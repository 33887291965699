import { createI18n } from "vue-i18n";
import acceptLanguage from "accept-language";
// import en from "../languages/en";
import pl from "../languages/pl";
// import ua from "../languages/ua";

const i18n = createI18n({
  locale:
    acceptLanguage.get(
      localStorage.getItem("language") || navigator.language.substring(0, 2)
    ) || "pl",
  fallbackLocale: "pl",
  messages: {
    // en,
    pl,
    // ua,
  },
});

export default i18n;

export const __ = i18n.global.t;
