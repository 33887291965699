export default {
  computed: {
    redirectToURL() {
      return `${window.location.origin}${this.$route.href}`;
    },
    landingPageUrl() {
      return process.env.VUE_APP_LANDING_PAGE
        ? `${process.env.VUE_APP_LANDING_PAGE}`
        : null;
    },
  },
  methods: {
    goToLandingPage(path = "", query = {}) {
      window.location = this.getLandingPageUrl(path, query);
    },
    getLandingPageUrl(path = "", query = {}) {
      Object.keys(query).forEach(
        (key) => query[key] === undefined && delete query[key]
      );

      const urlWithQuery = new URL(`${this.landingPageUrl}/${path}`);
      urlWithQuery.search = new URLSearchParams(query).toString();

      return urlWithQuery;
    },
  },
};
