import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

const notifyConf = {
  duration: 5000,
};

export default {
  namespaced: true,
  state: {
    referrals: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
  },

  getters: {
    getReferrals: (state) => state.referrals,
    getPagination: (state) => state.pagination,
  },
  mutations: {
    setReferrals(state, referrals) {
      state.referrals = referrals.data;
      state.pagination.currentPage = referrals.meta.current_page;
      state.pagination.total = referrals.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },

    clearReferrals(state) {
      state.referrals = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    setPagination(state, { currentPage, total }) {
      state.pagination.currentPage = currentPage;
      state.pagination.total = total;
    },
  },

  actions: {
    async fetchReferrals({ commit, state }, id) {
      const { data } = await http.get(`/referrals/check-results/${id}`, {
        params: {
          page: state.pagination.currentPage,
        },
      });
      commit("setPagination", {
        currentPage: data.meta.current_page,
        total: data.meta.last_page,
      });
      commit("setReferrals", data);
    },
    async saveInvoiceData({ state }, { id, form }) {
      try {
        await http.post(`referralGroups/referral-save-invoice/${id}`, form);
        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch (error) {
        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });
      }
    },
  },
};
