export default {
  namespaced: true,
  state: {
    languages: [
      // { name: "english", code: "en" },
      { name: "polish", code: "pl" },
      // { name: "ukrainian", code: "ua" },
    ],
  },
  getters: {
    getAllLanguages: (state) => state.languages,
    getLanguageByCode: (state) => (code) =>
      state.languages.find((language) => language.code === code),
  },
  mutations: {},
  actions: {},
};
